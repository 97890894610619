import React from "react";
import './Home.css';


import Keralahindu from "./Hinduism";
import Mission from "./Khsperth";
import Slider from "./Slider_steps";
import Exec from './img/executive-committee.jpg';
import Vani from './img/vanitha.jpg';
import news1 from './img/001.jpg';
import news2 from './img/002.jpg';
import news3 from './img/003.jpg';
import news4 from './img/004.jpg';
import news5 from './img/005.jpg';
import news6 from './img/006.jpg';
import news7 from './img/007.jpg';
import news8 from './img/008.jpg';
import news9 from './img/009.jpg';
import news10 from './img/0010.jpg';
import news11 from './img/0011.jpg';
import news12 from './img/0012.jpg';
import news13 from './img/0012.jpg';
import news14 from './img/0013.jpg';
import news15 from './img/0014.webp';
import news16 from './img/0015.jpg';
import news17 from './img/0016.jpg';
import news18 from './img/ayyappa.jpg';


import './Media.css';
function Home() {
    return (
        <frameElement>
            <Keralahindu />
            <div className="awardPanel">
                <div className="award-col-left"><h2><a href="/static/media/KHSP-Membership-Form.pdf" target="_blank">Download Membership Form</a></h2></div>
                <div className="award-col-right"></div>
            </div>
            <Mission />
            <div className="globalNetwork"></div>
            <div className="globalMap"></div>

            <Slider />

            <div className="homePanel1">
            <div className="homePanel_left_bt"><img src={Exec} alt="KHSP - Executive Committee" /></div>
            <div className="homePanel_right_bt"><img src={Vani} alt="KHSP - Vanitha Committee" /></div>
        </div>
        <div className="awardPanel">
        <div className="award-col-left"><h2>KHSP - News</h2></div>
        <div className="award-col-right"></div>
    </div>
    <div className="homePanel1">
    <div className="homePanel_left_bt"><img src={news1} alt="KHSP - News" /></div>
    <div className="homePanel_right_bt"><img src={news2} alt="KHSP - News" /></div>
</div>
<div className="homePanel1">
    <div className="homePanel_left_bt"><img src={news3} alt="KHSP - News" /></div>
    <div className="homePanel_right_bt"><img src={news4} alt="KHSP - News" /></div>
</div>
<div className="homePanel1">
    <div className="homePanel_left_bt"><img src={news5} alt="KHSP - News" /></div>
    <div className="homePanel_right_bt"><img src={news6} alt="KHSP - News" /></div>
</div>
<div className="homePanel1">
    <div className="homePanel_left_bt"><img src={news7} alt="KHSP - News" /></div>
    <div className="homePanel_right_bt"><img src={news8} alt="KHSP - News" /></div>
</div>
<div className="homePanel1">
    <div className="homePanel_left_bt"><img src={news9} alt="KHSP - News" /></div>
    <div className="homePanel_right_bt"><img src={news10} alt="KHSP - News" /></div>
</div>
<div className="homePanel1">
    <div className="homePanel_left_bt"><img src={news11} alt="KHSP - News" /></div>
    <div className="homePanel_right_bt"><img src={news12} alt="KHSP - News" /></div>
</div>
<div className="homePanel1">
    <div className="homePanel_left_bt"><img src={news13} alt="KHSP - News" /></div>
    <div className="homePanel_right_bt"><img src={news14} alt="KHSP - News" /></div>
</div>
<div className="homePanel1">
    <div className="homePanel_left_bt"><img src={news15} alt="KHSP - News" /></div>
    <div className="homePanel_right_bt"><img src={news16} alt="KHSP - News" /></div>
</div>
<div className="homePanel1">
    <div className="homePanel_left_bt"><img src={news17} alt="KHSP - News" /></div>
    <div className="homePanel_right_bt"><img src={news18} alt="KHSP - News" /></div>
</div>




        </frameElement>
    )
}

export default Home;







//import logo from './logo.svg';

//import 'react-alice-carousel/lib/alice-carousel.css';
import './App.css';
import Header from './Header';
import Home from './Home';
import Footer from './Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <Footer />
    </div>
  );
}

export default App;
